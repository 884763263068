<template>
  <v-app>
    <Navigation />
    <transition
        name="fade"
        mode="out-in">
      <router-view></router-view>
    </transition>
  </v-app>
</template>

<script>
import Navigation from './components/Navigation';

export default {
  name: 'App',

  components: {
    Navigation,
  },

  data: () => ({
    //
  }),
}
</script>
