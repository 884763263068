<template>
<div class="navbar">
   <!--------------- navbar starts here --------------->
            <div class="logo">
                  <img src="Logo.svg" alt="" class="d-none d-md-flex">
                  <img src="Logo.svg" alt="" style="height:70px" class="d-flex d-md-none">
            </div>
                  <ul id="menu" >
                        <div id="navitem" class="d-none d-md-flex">
                              <router-link to="/">Home </router-link>
                              <router-link to="/about">Over mij </router-link> 
                              <router-link to="/pricelist">Behandelingen </router-link> 
                              <router-link to="/products">Producten</router-link>
                        </div>
                        <v-app-bar-nav-icon @click="drawer = true" class="d-sm-flex d-md-none"></v-app-bar-nav-icon>
                  </ul>
                        <v-navigation-drawer v-model="drawer" absolute temporary width="50%">
                              <v-list nav>
                              <v-list-item-group v-model="group">
                              
                                    <v-list-item router-link to="/" style="text-decoration:none; color:#728d69;">
                                          <v-list-item-title>Home</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item router-link to="/about" style="text-decoration:none; color:#728d69;">
                                          <v-list-item-title>Over mij</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item router-link to="/pricelist" style="text-decoration:none; color:#728d69;">
                                          <v-list-item-title>Behandelingen</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item router-link to="/products" style="text-decoration:none; color:#728d69;">
                                          <v-list-item-title>Producten</v-list-item-title>
                                    </v-list-item>
                             

                              </v-list-item-group>
                              </v-list>
                        </v-navigation-drawer>
            <!--------------- navbar ends here --------------->
</div>
</template>

<script>

export default {
data: () => ({
    drawer: false,
  }),
}
</script>

<style>

</style>