<template>
<div>

  <div class="home">
    <div class="big-img">
      <div class="img">
        
      </div>
    </div>
    <div class="small-img">
      <div class="img">

      </div>
    </div>

    <div class="big-text">
      <h1 class="d-none d-md-flex"></h1>
      
    </div>
    <div class="place">
      
      
    </div>
  </div>

  <div class="products">
    <div class="quote-text">
      <div class="quote d-none d-md-flex">
        <img src="quote.svg" style="width:98%;" alt="Love the skin you're in">
      </div>
      <div class="product-text">
        <h1>Producten</h1>
      </div>
    </div>
    <div class="product-slider">
      <div class="slider">
        <carousel :autoplay="true" :perPage="1" :perPageCustom="[[768, 2], [1024, 3]]" :autoplayTimeout="5000">
          <slide v-for="(image, index) in data.Highlight" style="max-width:100%;">
            <img :src="'https://login-beautysalon.nl/' + image.path" alt="" style="max-height:350px; width:100%; padding:5px;" >
          </slide>

        </carousel>
      </div>    
    </div>
    <div class="big-product">
      <img src="Producten.svg" alt="">
    </div>
    
  </div>

  <div class="about">
    <div class="about-block d-none d-md-flex">
      <div class="block">
        <h1>ABOUT</h1>
      </div>
    </div>
    <div class="about-img d-none d-md-flex">
      <v-img class="pic" :src="'https://login-beautysalon.nl/' + data.Image.path">
          </v-img>
    </div>
    <div class="about-text">
      <div class="heading">
        <h1>Over mij</h1>
      </div>
      <div class="text">
        <p>{{data.AboutMe}}</p>
      </div>
        <router-link to="/about"><v-btn tile color="black" class="button white--text">Lees meer</v-btn></router-link>
    </div>
  </div>

  <div class="contact" >
          <div class="big-text">
            <h1>CONTACT</h1>
          </div>

          <div class="address">
            <h1>ADRES</h1>
            <h2>Leeberg 6</h2>
            <h2>5944 EB Arcen</h2>
          </div>

          <div class="phone">
            <h1>TELEFOONNUMMER</h1>
            <h2>0618168562</h2>
          </div>

          <div class="mail">
            <h1>E-MAILADRES</h1>
            <h2>info@beautysalon-kelly.nl</h2>
          </div>

          <div class="social">
            <h1>VIND MIJ OP SOCIAL MEDIA</h1>
            <a href="https://www.facebook.com/Kellys-Beautysalon-779445252204006" target="_blank"><h2 class="fb">Facebook</h2></a>
            <a href="https://www.instagram.com/kellydriessen/" target="_blank"><h2 class="ig">Instagram</h2></a>  
          </div>

    <div class="form d-none d-md-flex"></div>
  </div>

</div> 
  

</template>

<script>
  import { Carousel, Slide } from 'vue-carousel';
  import axios from 'axios'

  
  const url="https://login-beautysalon.nl/api/singletons/get/About?token=749159b39575cd13e793ad25fd9146";

export default {
  name: 'home',
  components: { 
    Carousel,
    Slide
    },

  data (){
    return {
      data: [],
      options: {
            arrows: false,
            responsive: [
                {
                    breakpoint: 0,
                    settings: {
                      navButtons: false,  
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                      navButtons: false,  
                      autoplay: true,
                      autoplaySpeed: 5000,
                      arrows: false,
                      dots: false,
                      infinite: true,
                      pauseOnHover: false,
                    }
                },
                
                {
                    breakpoint: 900,
                    settings: {
                        autoplay: true,
                        autoplaySpeed: 5000,
                        dots: true,
                        infinite: true,
                        navButtons: false,  
                        pauseOnHover: true,        
                    }
                }
            ]
        }
    }
},
  created () {
    axios
    .get(url)
    .then(singleton => {
      this.data = singleton.data
      // console.log(this.data)
    })
    .catch(error => {
      console.log(error);
    })    
  }
}
</script>

<style lang="scss">
@import "@/css/style.scss"
</style>
